<template>
  <div class="order">
    <panel-title icon="el-icon-alarm-clock" title="我的预约" />

    <panel-content>
      <div slot="filter" class="filter">
        <div class="top">
          <div class="left">
            <span class="active">全部预约</span>
            <span>等待确认</span>
            <span>预约成功</span>
            <span>预约失败</span>
          </div>
          <div class="right">
            <el-link type="primary">预约服务商</el-link>
          </div>
        </div>
        <div class="bottom">
          <span>时间：</span>
          <el-date-picker
            size="medium"
            style="margin: 0 10px"
            v-model="value1"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-input
            size="medium"
            style="width: 180px; margin: 0 10px"
            placeholder="请输入预约对象"
          />
          <el-button size="medium" type="primary">立即查询</el-button>
        </div>
      </div>

      <div slot="content" class="table-wrap">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column label="预约对象"> </el-table-column>

          <el-table-column prop="name" label="预约内容" width="120">
          </el-table-column>

          <el-table-column prop="name" label="产品类型" width="120">
          </el-table-column>

          <el-table-column prop="name" label="预约时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="状态" width="120">
          </el-table-column>

          <el-table-column prop="name" label="创建时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作" width="120">
          </el-table-column>
        </el-table>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },
};
</script>

<style lang="scss" scoped>
.order {
  .filter {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        span {
          cursor: pointer;
          display: inline-block;
          padding-right: 20px;
          margin-right: 20px;
          border-right: 1px solid #ccc;

          font-size: 14px;

          line-height: 17px;

          &:hover,
          &:active,
          &.active {
            color: red;
          }
        }

        span:last-child {
          border-right: none;
        }
      }
    }

    .bottom {
      margin-top: 15px;
    }
  }

  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>